import React from 'react'

const HumanBodyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99992 5.00001C10.9204 5.00001 11.6666 4.25381 11.6666 3.33334C11.6666 2.41286 10.9204 1.66667 9.99992 1.66667C9.07944 1.66667 8.33325 2.41286 8.33325 3.33334C8.33325 4.25381 9.07944 5.00001 9.99992 5.00001Z"
      fill="#52B788"
    />
    <path
      d="M11.6666 18.3333C12.1268 18.3333 12.4999 17.9602 12.4999 17.5V7.49999H15.8333C16.2935 7.49999 16.6666 7.1269 16.6666 6.66666C16.6666 6.20642 16.2935 5.83333 15.8333 5.83333H4.16659C3.70635 5.83333 3.33325 6.20642 3.33325 6.66666C3.33325 7.1269 3.70635 7.49999 4.16659 7.49999H7.49992V17.5C7.49992 17.9602 7.87301 18.3333 8.33325 18.3333C8.79349 18.3333 9.16658 17.9602 9.16658 17.5V12.5H10.8333V17.5C10.8333 17.9602 11.2063 18.3333 11.6666 18.3333Z"
      fill="#52B788"
    />
  </svg>
)

export default HumanBodyIcon
