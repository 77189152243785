import React from 'react'

const LinkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.64001 18.2401C7.08008 18.5095 6.45031 18.5984 5.83768 18.4945C5.22506 18.3906 4.65981 18.099 4.22001 17.6601C3.94033 17.3813 3.71842 17.0502 3.56701 16.6855C3.41559 16.3209 3.33765 15.9299 3.33765 15.5351C3.33765 15.1402 3.41559 14.7493 3.56701 14.3846C3.71842 14.0199 3.94033 13.6888 4.22001 13.4101L9.88001 7.76005C10.4425 7.19825 11.205 6.88269 12 6.88269C12.795 6.88269 13.5575 7.19825 14.12 7.76005C14.6818 8.32255 14.9974 9.08505 14.9974 9.88005C14.9974 10.6751 14.6818 11.4375 14.12 12.0001L12 14.1201"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.36 5.76003C16.92 5.49062 17.5497 5.40172 18.1624 5.50561C18.775 5.60951 19.3402 5.90107 19.78 6.34003C20.0597 6.61874 20.2816 6.94991 20.4331 7.31457C20.5845 7.67922 20.6624 8.07018 20.6624 8.46503C20.6624 8.85987 20.5845 9.25083 20.4331 9.61548C20.2816 9.98014 20.0597 10.3113 19.78 10.59L14.12 16.24C13.5575 16.8018 12.795 17.1174 12 17.1174C11.205 17.1174 10.4425 16.8018 9.88005 16.24C9.31824 15.6775 9.00269 14.915 9.00269 14.12C9.00269 13.325 9.31824 12.5625 9.88005 12L12 9.88003"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LinkIcon
