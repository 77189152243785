import React from 'react'

const BannerInfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M12.875 14.7499H12.25V14.7499C11.5596 14.7499 11 14.1902 11 13.4999V13.4999V10.3749C11 10.0297 10.7202 9.74988 10.375 9.74988H9.75"
      stroke="#A2B6ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 20.3749C16.1777 20.3749 20.375 16.1775 20.375 10.9999C20.375 5.82221 16.1777 1.62488 11 1.62488C5.82233 1.62488 1.625 5.82221 1.625 10.9999C1.625 16.1775 5.82233 20.3749 11 20.3749Z"
      stroke="#4460AC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="10" cy="6.99988" r="1" fill="#A2B6ED" />
  </svg>
)

export default BannerInfoIcon
