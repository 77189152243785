import React from 'react'

interface CalendarIconProps extends React.SVGProps<SVGSVGElement> {
  useCurrentColor?: boolean
}

const CalendarIcon = ({
  useCurrentColor = false,
  ...restProps
}: CalendarIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M17 4.75H7C4.79086 4.75 3 6.54086 3 8.75V18C3 20.2091 4.79086 22 7 22H17C19.2091 22 21 20.2091 21 18V8.75C21 6.54086 19.2091 4.75 17 4.75Z"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.25V6.25"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 3.25V6.25"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 9.75H21"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13.99V14"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.99V14"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 13.99V14"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 17.99V18"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17.99V18"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 17.99V18"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CalendarIcon
