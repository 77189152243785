import React from 'react'

const EyeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.6569 8.15392L20.0893 10.5864C20.4644 10.9614 20.6751 11.4702 20.6751 12.0006C20.6751 12.531 20.4644 13.0397 20.0893 13.4148L17.6569 15.8472C16.1566 17.3475 14.1218 18.1904 12 18.1904C9.87829 18.1904 7.84346 17.3475 6.34317 15.8472L3.91072 13.4148C3.53565 13.0397 3.32493 12.531 3.32493 12.0006C3.32493 11.4702 3.53565 10.9614 3.91072 10.5864L6.34317 8.15392C7.84346 6.65363 9.87829 5.81078 12 5.81078C14.1218 5.81078 16.1566 6.65363 17.6569 8.15392Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.50002 10.6193 9.50002 12C9.50002 13.3807 10.6193 14.5 12 14.5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EyeIcon
