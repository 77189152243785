/* eslint-disable arrow-body-style */
import * as React from 'react'

const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => {
  const { stroke } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_182_17607)">
        <path
          d="M3.45825 7.30821L20.5749 3.66655"
          stroke={stroke || 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3332 2.65238L9.66655 3.43155V3.43155C8.99098 3.57364 8.55851 4.23649 8.7006 4.91206C8.70092 4.91356 8.70123 4.91505 8.70155 4.91654L8.96155 6.13988L15.0749 4.83988L14.8149 3.61738V3.61738C14.6724 2.94188 14.0093 2.50978 13.3338 2.65225C13.3336 2.65229 13.3334 2.65234 13.3332 2.65238L13.3332 2.65238Z"
          stroke={stroke || 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.9583 16.9999V10.7499"
          stroke={stroke || 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.7083 16.9999V10.7499"
          stroke={stroke || 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5217 6.99988H19.7092L18.555 20.2282V20.2282C18.5011 20.8779 17.9569 21.3771 17.305 21.3749H8.35917V21.3749C7.70915 21.3745 7.1679 20.876 7.11417 20.2282L6.25 9.81404"
          stroke={stroke || 'currentColor'}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_17607">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(2 1.99988)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DeleteIcon
