import React from 'react'

const CaretUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5245 10.4959C11.7477 10.7237 12.1095 10.7237 12.3326 10.4959C12.5558 10.2681 12.5558 9.89874 12.3326 9.67094L8.90406 6.17094C8.6809 5.94313 8.3191 5.94313 8.09594 6.17094L4.66737 9.67094C4.44421 9.89874 4.44421 10.2681 4.66737 10.4959C4.89052 10.7237 5.25233 10.7237 5.47549 10.4959L8.5 7.40837L11.5245 10.4959Z"
      fill="#717171"
    />
  </svg>
)

export default CaretUpIcon
