import React from 'react'

interface ClipboardIconProps extends React.SVGProps<SVGSVGElement> {
  useCurrentColor?: boolean
}

const ClipboardIcon = ({
  useCurrentColor,
  ...restProps
}: ClipboardIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M15.12 4.25H16C16.7956 4.25 17.5587 4.56607 18.1213 5.12868C18.6839 5.69129 19 6.45435 19 7.25V18.25C19 19.0456 18.6839 19.8087 18.1213 20.3713C17.5587 20.9339 16.7956 21.25 16 21.25H8C7.20435 21.25 6.44129 20.9339 5.87868 20.3713C5.31607 19.8087 5 19.0456 5 18.25V7.25C5 6.45435 5.31607 5.69129 5.87868 5.12868C6.44129 4.56607 7.20435 4.25 8 4.25H8.9"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M15 13H9"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6 16H9"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 10H9"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 2.75H10.5C9.67157 2.75 9 3.42157 9 4.25C9 5.07843 9.67157 5.75 10.5 5.75H13.5C14.3284 5.75 15 5.07843 15 4.25C15 3.42157 14.3284 2.75 13.5 2.75Z"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)

export default ClipboardIcon
