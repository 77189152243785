import React from 'react'

const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.875 17.6279V18.8779C18.875 19.5683 18.3154 20.1279 17.625 20.1279H13.875"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.875 5.12793H17.625V5.12793C18.3154 5.12793 18.875 5.68757 18.875 6.37793V7.62793"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6617 21.3714L3.16167 20.2998V20.2998C2.85388 20.2558 2.6252 19.9923 2.625 19.6814V5.10392L2.625 5.10389C2.62477 4.80894 2.83078 4.55398 3.1192 4.49225L10.6192 2.64142V2.64142C10.9567 2.56916 11.2889 2.78421 11.3612 3.12174C11.3704 3.16491 11.3751 3.20894 11.375 3.25308V20.7531V20.7531C11.3748 21.0983 11.0948 21.3779 10.7497 21.3777C10.7202 21.3777 10.6908 21.3756 10.6617 21.3714L10.6617 21.3714Z"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.875 10.1274L21.375 12.6274H14.5"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.875 15.125L21.375 12.625"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default LogoutIcon
