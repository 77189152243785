import React from 'react'

const CheckmarkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.8125 2.1875L7.33194 17.159V17.159C6.84997 17.8516 5.89782 18.0223 5.20525 17.5403C5.07046 17.4465 4.95168 17.3316 4.85347 17.2L2.1875 13.6458"
      stroke="#4460AC"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CheckmarkIcon
