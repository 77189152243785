import React from 'react'

const OrganizationIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.4502 2V22M6.9502 4L15.0502 7.5C18.3502 8.9 18.3502 11.3 15.2502 12.9L6.9502 17"
      stroke="#717171"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default OrganizationIcon
