import React from 'react'

const DocumentIcon: React.FC = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_584_12905)">
      <path
        d="M32.5 13.3333V34.1667C32.5 35.0875 31.7542 35.8333 30.8333 35.8333H9.16667C8.24583 35.8333 7.5 35.0875 7.5 34.1667V5.83332C7.5 4.91249 8.24583 4.16666 9.16667 4.16666H23.3333L32.5 13.3333Z"
        fill="#E4F8FF"
      />
      <path
        d="M23.333 4.16666V11.6667C23.333 12.5875 24.0788 13.3333 24.9997 13.3333H32.4997L23.333 4.16666Z"
        fill="#4460AC"
      />
    </g>
    <defs>
      <clipPath id="clip0_584_12905">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="matrix(-1 0 0 1 40 0)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default DocumentIcon
