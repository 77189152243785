import React from 'react'

const LiteratureBookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.0476 4H12V19.5152H21.0476C21.5738 19.5152 22 19.0812 22 18.5455V4.9697C22 4.43394 21.5738 4 21.0476 4Z"
      fill="#0078D4"
    />
    <path
      d="M12 4H2.95238C2.42619 4 2 4.43394 2 4.9697V18.5455C2 19.0812 2.42619 19.5152 2.95238 19.5152H12V4Z"
      fill="#1B9DE2"
    />
    <path
      d="M10.5713 18.5456C10.5713 19.349 11.2108 20.0002 11.9999 20.0002V17.0911C11.2108 17.0911 10.5713 17.7422 10.5713 18.5456Z"
      fill="#1B9DE2"
    />
    <path
      d="M13.4286 18.5456C13.4286 17.7422 12.789 17.0911 12 17.0911V20.0002C12.789 20.0002 13.4286 19.349 13.4286 18.5456Z"
      fill="#0078D4"
    />
    <path
      d="M11.9998 4H3.42834C3.16548 4 2.95215 4.21721 2.95215 4.48485V18.0606C2.95215 18.3282 3.16548 18.5455 3.42834 18.5455H11.9998V4Z"
      fill="white"
    />
    <path
      d="M20.5714 4H12V18.5455H20.5714C20.8343 18.5455 21.0476 18.3282 21.0476 18.0606V4.48485C21.0476 4.21721 20.8343 4 20.5714 4Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.3332 6.90915H4.61895C4.48753 6.90915 4.38086 6.80054 4.38086 6.66673V6.18188C4.38086 6.04806 4.48753 5.93945 4.61895 5.93945H10.3332C10.4647 5.93945 10.5713 6.04806 10.5713 6.18188V6.66673C10.5713 6.80054 10.4647 6.90915 10.3332 6.90915Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.3332 8.8486H4.61895C4.48753 8.8486 4.38086 8.74 4.38086 8.60618V8.12133C4.38086 7.98751 4.48753 7.87891 4.61895 7.87891H10.3332C10.4647 7.87891 10.5713 7.98751 10.5713 8.12133V8.60618C10.5713 8.74 10.4647 8.8486 10.3332 8.8486Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.3332 10.7881H4.61895C4.48753 10.7881 4.38086 10.6795 4.38086 10.5456V10.0608C4.38086 9.92697 4.48753 9.81836 4.61895 9.81836H10.3332C10.4647 9.81836 10.5713 9.92697 10.5713 10.0608V10.5456C10.5713 10.6795 10.4647 10.7881 10.3332 10.7881Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.3332 12.7273H4.61895C4.48753 12.7273 4.38086 12.6187 4.38086 12.4848V12C4.38086 11.8662 4.48753 11.7576 4.61895 11.7576H10.3332C10.4647 11.7576 10.5713 11.8662 10.5713 12V12.4848C10.5713 12.6187 10.4647 12.7273 10.3332 12.7273Z"
      fill="#F0F0F0"
    />
    <path
      d="M10.3332 14.6667H4.61895C4.48753 14.6667 4.38086 14.5581 4.38086 14.4243V13.9394C4.38086 13.8056 4.48753 13.697 4.61895 13.697H10.3332C10.4647 13.697 10.5713 13.8056 10.5713 13.9394V14.4243C10.5713 14.5581 10.4647 14.6667 10.3332 14.6667Z"
      fill="#F0F0F0"
    />
    <path
      d="M9.38086 16.6059H4.61895C4.48753 16.6059 4.38086 16.4973 4.38086 16.3635V15.8787C4.38086 15.7448 4.48753 15.6362 4.61895 15.6362H9.38086C9.51229 15.6362 9.61895 15.7448 9.61895 15.8787V16.3635C9.61895 16.4973 9.51229 16.6059 9.38086 16.6059Z"
      fill="#F0F0F0"
    />
    <path
      d="M19.3811 6.90915H13.6668C13.5354 6.90915 13.4287 6.80054 13.4287 6.66673V6.18188C13.4287 6.04806 13.5354 5.93945 13.6668 5.93945H19.3811C19.5125 5.93945 19.6192 6.04806 19.6192 6.18188V6.66673C19.6192 6.80054 19.5125 6.90915 19.3811 6.90915Z"
      fill="#E0E0E0"
    />
    <path
      d="M19.3811 8.8486H13.6668C13.5354 8.8486 13.4287 8.74 13.4287 8.60618V8.12133C13.4287 7.98751 13.5354 7.87891 13.6668 7.87891H19.3811C19.5125 7.87891 19.6192 7.98751 19.6192 8.12133V8.60618C19.6192 8.74 19.5125 8.8486 19.3811 8.8486Z"
      fill="#E0E0E0"
    />
    <path
      d="M19.3811 10.7881H13.6668C13.5354 10.7881 13.4287 10.6795 13.4287 10.5456V10.0608C13.4287 9.92697 13.5354 9.81836 13.6668 9.81836H19.3811C19.5125 9.81836 19.6192 9.92697 19.6192 10.0608V10.5456C19.6192 10.6795 19.5125 10.7881 19.3811 10.7881Z"
      fill="#E0E0E0"
    />
    <path
      d="M19.3811 12.7273H13.6668C13.5354 12.7273 13.4287 12.6187 13.4287 12.4848V12C13.4287 11.8662 13.5354 11.7576 13.6668 11.7576H19.3811C19.5125 11.7576 19.6192 11.8662 19.6192 12V12.4848C19.6192 12.6187 19.5125 12.7273 19.3811 12.7273Z"
      fill="#E0E0E0"
    />
    <path
      d="M19.3811 14.6667H13.6668C13.5354 14.6667 13.4287 14.5581 13.4287 14.4243V13.9394C13.4287 13.8056 13.5354 13.697 13.6668 13.697H19.3811C19.5125 13.697 19.6192 13.8056 19.6192 13.9394V14.4243C19.6192 14.5581 19.5125 14.6667 19.3811 14.6667Z"
      fill="#E0E0E0"
    />
    <path
      d="M18.4287 16.6059H13.6668C13.5354 16.6059 13.4287 16.4973 13.4287 16.3635V15.8787C13.4287 15.7448 13.5354 15.6362 13.6668 15.6362H18.4287C18.5601 15.6362 18.6668 15.7448 18.6668 15.8787V16.3635C18.6668 16.4973 18.5601 16.6059 18.4287 16.6059Z"
      fill="#E0E0E0"
    />
  </svg>
)

export default LiteratureBookIcon
