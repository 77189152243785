import React from 'react'

const MoreHorizontalIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16667 8.33319C3.25 8.33319 2.5 9.08319 2.5 9.99986C2.5 10.9165 3.25 11.6665 4.16667 11.6665C5.08333 11.6665 5.83333 10.9165 5.83333 9.99986C5.83333 9.08319 5.08333 8.33319 4.16667 8.33319ZM15.8333 8.33319C14.9167 8.33319 14.1667 9.08319 14.1667 9.99986C14.1667 10.9165 14.9167 11.6665 15.8333 11.6665C16.75 11.6665 17.5 10.9165 17.5 9.99986C17.5 9.08319 16.75 8.33319 15.8333 8.33319ZM8.33333 9.99986C8.33333 9.08319 9.08333 8.33319 10 8.33319C10.9167 8.33319 11.6667 9.08319 11.6667 9.99986C11.6667 10.9165 10.9167 11.6665 10 11.6665C9.08333 11.6665 8.33333 10.9165 8.33333 9.99986Z"
      fill="#717171"
    />
  </svg>
)

export default MoreHorizontalIcon
