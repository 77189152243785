import React from 'react'

const EditIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0764 2.92349V2.92349C16.0874 1.93871 14.4873 1.94213 13.5026 2.93112C13.4971 2.93665 13.4916 2.94221 13.4861 2.94779L3.41667 13.0172L2.1875 17.8124L6.98264 16.5825L17.0521 6.51307V6.51307C18.05 5.5384 18.0688 3.93933 17.0941 2.94145C17.0882 2.93543 17.0823 2.92944 17.0764 2.92349L17.0764 2.92349Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2012 3.23596L16.7637 6.79846"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.41699 13.0138L6.98644 16.5763"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EditIcon
