import React from 'react'

const PinpointIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1274_42227)">
      <path
        d="M2.625 21.375H21.375"
        // stroke="#717171"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.25 8.875C18.25 12.3267 12 17.625 12 17.625C12 17.625 5.75 12.3267 5.75 8.875V8.875C5.75 5.42322 8.54822 2.625 12 2.625C15.4518 2.625 18.25 5.42322 18.25 8.875V8.875V8.875Z"
        // stroke="#717171"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1274_42227">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
)

export default PinpointIcon
