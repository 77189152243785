import React from 'react'

const SlashedEyeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.69 9.18994L20.09 10.5899C20.4625 10.9647 20.6715 11.4716 20.6715 11.9999C20.6715 12.5283 20.4625 13.0352 20.09 13.4099L17.66 15.8399C16.7033 16.8023 15.5175 17.5054 14.2143 17.8833C12.911 18.2611 11.533 18.3013 10.21 17.9999"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.29995 16.6601C6.94504 16.4142 6.61061 16.1399 6.29995 15.8401L3.90995 13.4101C3.53745 13.0353 3.32837 12.5284 3.32837 12.0001C3.32837 11.4717 3.53745 10.9648 3.90995 10.5901L6.33995 8.16006C7.66601 6.83045 9.41752 6.00969 11.2878 5.84149C13.1581 5.6733 15.028 6.16838 16.57 7.24006"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.11 13.6299C9.70476 13.1477 9.49647 12.5302 9.52683 11.901C9.55719 11.2718 9.82397 10.6772 10.2738 10.2363C10.7236 9.79527 11.3234 9.54035 11.953 9.52249C12.5827 9.50463 13.1959 9.72514 13.67 10.1399"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8901 3.07007L2.93005 21.0301"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SlashedEyeIcon
