import React from 'react'

const ChecklistIcon: React.FC = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7782 3.33527L13.7783 3.33516C13.809 3.27341 13.7838 3.19839 13.722 3.16765C12.7009 2.66024 11.5501 2.375 10.333 2.375C6.12183 2.375 2.70801 5.78883 2.70801 10C2.70801 14.2112 6.12183 17.625 10.333 17.625C14.5442 17.625 17.958 14.2112 17.958 10C17.958 9.71376 17.9422 9.4311 17.9115 9.15292C17.9039 9.08429 17.8421 9.03487 17.7736 9.04238C17.7049 9.05 17.6554 9.11185 17.663 9.18035C17.663 9.18037 17.663 9.18039 17.663 9.18041L17.166 9.23533L13.7782 3.33527ZM13.7782 3.33527C13.7476 3.397 13.6726 3.42228 13.6107 3.39155L13.7782 3.33527Z"
      stroke="currentColor"
    />
    <path
      d="M17.9214 4.25507L17.9214 4.25507L10.4214 11.755L10.4212 11.7552C10.3979 11.7785 10.3662 11.7917 10.333 11.7917C10.2998 11.7917 10.2681 11.7785 10.2448 11.7552L10.2446 11.755L7.74462 9.25505C7.74462 9.25505 7.74462 9.25505 7.74462 9.25505C7.6958 9.20622 7.69581 9.12714 7.74462 9.07832C7.79344 9.0295 7.87258 9.0295 7.92139 9.07832L7.9214 9.07833L9.97946 11.1363L10.333 11.4899L10.6866 11.1363L17.7446 4.0783C17.7935 4.02948 17.8726 4.02948 17.9214 4.0783C17.9702 4.12711 17.9702 4.20626 17.9214 4.25507Z"
      stroke="currentColor"
    />
  </svg>
)

export default ChecklistIcon
