import React from 'react'

const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9997 10.8887V17.5553M11.9997 17.5553L14.8569 14.7776M11.9997 17.5553L9.14258 14.7776"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.2852 2.55469V3.99916C14.2852 5.24373 14.2852 5.86601 14.5274 6.34137C14.7404 6.7595 15.0804 7.09946 15.4985 7.31251C15.9739 7.55472 16.5962 7.55472 17.8407 7.55472H19.428"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 7.33333C4 5.46649 4 4.53307 4.36331 3.82003C4.68289 3.19282 5.19282 2.68289 5.82003 2.36331C6.53307 2 7.46649 2 9.33333 2H13.2633C14.0629 2 14.4627 2 14.8397 2.08884C15.1741 2.16762 15.4942 2.29759 15.7888 2.47417C16.1211 2.67331 16.4078 2.95199 16.9811 3.50936L18.3844 4.87374C18.9803 5.45308 19.2783 5.74275 19.4915 6.08314C19.6805 6.3849 19.82 6.71501 19.9046 7.0609C20 7.45107 20 7.86662 20 8.69771V16.6667C20 18.5335 20 19.4669 19.6367 20.18C19.3171 20.8072 18.8072 21.3171 18.18 21.6367C17.4669 22 16.5335 22 14.6667 22H9.33333C7.46649 22 6.53307 22 5.82003 21.6367C5.19282 21.3171 4.68289 20.8072 4.36331 20.18C4 19.4669 4 18.5335 4 16.6667V7.33333Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default DownloadIcon
