import React from 'react'

const LogoIcon: React.FC = () => (
  <svg
    width="136"
    height="40"
    viewBox="0 0 136 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_69_866)">
      <path
        d="M54.0443 21.973C54.038 20.8002 54.2397 19.6355 54.6399 18.5328C55.0195 17.48 55.6156 16.518 56.3899 15.7086C57.1924 14.8842 58.1582 14.2356 59.2257 13.804C61.612 12.8979 64.2446 12.8736 66.6473 13.7356C67.6801 14.1275 68.6172 14.7347 69.3961 15.5166C70.1761 16.3205 70.7826 17.2754 71.1779 18.3225C71.6239 19.5018 71.8666 20.7479 71.8956 22.008C71.9191 22.4531 71.9308 22.9945 71.9308 23.6321C71.9308 24.2731 71.9659 24.9675 72.0345 25.7453C72.1124 26.5615 72.2415 27.3721 72.421 28.1723C72.6183 29.0572 72.9117 29.918 73.296 30.7394H69.0983C68.7012 29.8807 68.4075 28.9781 68.2233 28.0504C67.6307 28.9217 66.8387 29.6398 65.9128 30.1452C64.9793 30.6571 63.8589 30.9136 62.5517 30.9147C61.3711 30.927 60.2014 30.6886 59.1203 30.2153C58.1008 29.7717 57.1835 29.1241 56.425 28.3125C55.666 27.4895 55.072 26.5291 54.675 25.4832C54.2471 24.3626 54.0332 23.1721 54.0443 21.973V21.973ZM63.0419 17.0139C62.2885 16.9933 61.5387 17.1239 60.8368 17.3978C60.2628 17.6299 59.7524 17.9949 59.3478 18.4627C58.959 18.9271 58.6738 19.4688 58.5113 20.0517C58.1611 21.3325 58.1611 22.6835 58.5113 23.9643C58.6738 24.5472 58.959 25.089 59.3478 25.5533C59.7524 26.0211 60.2628 26.3862 60.8368 26.6183C61.5387 26.8921 62.2885 27.0227 63.0419 27.0022C64.4919 27.0022 65.6295 26.6005 66.4549 25.797C67.2803 24.9936 67.6946 23.7306 67.698 22.008C67.6277 20.4701 67.2312 19.2533 66.5084 18.3575C65.7857 17.4617 64.6302 17.0139 63.0419 17.0139Z"
        fill="#4460AC"
      />
      <path
        d="M83.5919 27.0022C83.8844 27.006 84.1768 26.9887 84.4669 26.9504C84.6994 26.9154 84.9337 26.8753 85.1679 26.8286V30.7394C84.8634 30.7862 84.6124 30.8262 84.415 30.8613C84.0957 30.9042 83.7735 30.922 83.4513 30.9147C82.2024 30.9358 80.9626 30.6976 79.8108 30.2153C78.7736 29.7755 77.838 29.1278 77.062 28.3125C76.2951 27.4955 75.7 26.5335 75.312 25.4832C74.8987 24.3714 74.6912 23.1938 74.6997 22.008V9.08529H78.9007V13.8725H84.328V17.785H78.9007V22.0113C78.9007 23.478 79.2799 24.677 80.0383 25.6084C80.7968 26.5398 81.9813 27.0044 83.5919 27.0022Z"
        fill="#4460AC"
      />
      <path
        d="M95.1025 13.1013C96.4554 13.1013 97.6165 13.4212 98.5858 14.0611C99.5582 14.7066 100.348 15.5906 100.88 16.6283C101.411 15.591 102.2 14.7071 103.172 14.0611C104.14 13.4212 105.301 13.1013 106.657 13.1013C107.805 13.0786 108.945 13.2988 110.003 13.7473C110.94 14.1559 111.781 14.757 112.47 15.5116C113.152 16.2675 113.675 17.1524 114.008 18.1138C114.364 19.1412 114.542 20.2216 114.533 21.3086V30.7394H110.337V21.3086C110.337 20.0512 110.04 19.0208 109.445 18.2173C108.851 17.4139 107.924 17.0127 106.663 17.0139C105.402 17.0139 104.474 17.415 103.879 18.2173C103.285 19.0196 102.987 20.0501 102.986 21.3086V30.7394H98.7832V21.3086C98.7832 20.0512 98.4859 19.0208 97.8914 18.2173C97.297 17.4139 96.3673 17.0127 95.1025 17.0139C93.8432 17.0139 92.9158 17.415 92.3202 18.2173C91.7246 19.0196 91.4268 20.0501 91.4268 21.3086V30.7394H87.2392V21.3086C87.2307 20.2216 87.4083 19.1412 87.7645 18.1138C88.0975 17.1519 88.6216 16.2668 89.3054 15.5116C89.9942 14.7567 90.8352 14.1554 91.7731 13.7473C92.8251 13.3011 93.9595 13.081 95.1025 13.1013V13.1013Z"
        fill="#4460AC"
      />
      <path
        d="M116.745 21.973C116.738 20.8001 116.94 19.6353 117.341 18.5328C117.72 17.48 118.316 16.518 119.091 15.7086C119.895 14.8829 120.864 14.2342 121.935 13.804C124.321 12.8979 126.954 12.8736 129.356 13.7356C130.389 14.1279 131.326 14.735 132.105 15.5166C132.886 16.3222 133.494 17.2788 133.89 18.3275C134.336 19.507 134.578 20.753 134.608 22.013C134.63 22.4553 134.643 22.9962 134.643 23.6371C134.643 24.2781 134.678 24.9725 134.747 25.7503C134.825 26.5665 134.954 27.3771 135.133 28.1773C135.331 29.0619 135.624 29.9226 136.007 30.7445H131.807C131.41 29.8857 131.117 28.9831 130.932 28.0554C130.34 28.9267 129.548 29.6448 128.622 30.1502C127.687 30.6621 126.567 30.9186 125.261 30.9197C124.08 30.932 122.911 30.6936 121.829 30.2203C120.81 29.7767 119.893 29.1291 119.134 28.3175C118.375 27.4949 117.78 26.5344 117.384 25.4882C116.954 24.3664 116.737 23.1742 116.745 21.973V21.973ZM125.741 17.0139C124.988 16.9936 124.238 17.1242 123.536 17.3978C122.962 17.6295 122.452 17.9946 122.049 18.4627C121.66 18.9271 121.375 19.4688 121.212 20.0517C120.862 21.3325 120.862 22.6835 121.212 23.9643C121.375 24.5472 121.66 25.089 122.049 25.5533C122.452 26.0215 122.962 26.3866 123.536 26.6183C124.238 26.8918 124.988 27.0224 125.741 27.0022C127.191 27.0022 128.329 26.6005 129.156 25.797C129.982 24.9936 130.396 23.7306 130.399 22.008C130.328 20.4701 129.931 19.2533 129.207 18.3575C128.484 17.4617 127.328 17.0139 125.741 17.0139Z"
        fill="#4460AC"
      />
      <path
        d="M36.6296 23.2298C35.3012 23.0907 34.0246 22.6398 32.9044 21.914C31.7841 21.1882 30.852 20.2082 30.1842 19.054C29.5165 17.8998 29.1321 16.6044 29.0624 15.2736C28.9928 13.9427 29.2399 12.6144 29.7835 11.3971C30.3961 9.75804 30.4833 7.96959 30.033 6.27895C29.5827 4.58831 28.6172 3.07896 27.2698 1.95953C25.9225 0.840101 24.2599 0.165863 22.5118 0.0300123C20.7637 -0.105838 19.0165 0.303408 17.5117 1.20118C16.0068 2.09896 14.8186 3.44093 14.1114 5.04161C13.4041 6.64229 13.2127 8.42263 13.5635 10.1366C13.9144 11.8505 14.7902 13.4134 16.0699 14.6092C17.3496 15.805 18.9701 16.5747 20.7073 16.8119V16.8119C21.9844 16.987 23.2046 17.4511 24.2745 18.1687C25.3444 18.8862 26.2357 19.8383 26.8805 20.9521C27.5252 22.0659 27.9062 23.312 27.9944 24.5952C28.0826 25.8784 27.8757 27.1647 27.3894 28.3559V28.3559C26.7248 29.9767 26.5815 31.7636 26.9792 33.4692C27.3769 35.1749 28.296 36.7152 29.6094 37.877C30.9227 39.0388 32.5655 39.7648 34.3105 39.9545C36.0554 40.1442 37.8164 39.7883 39.3497 38.9359C40.8831 38.0836 42.1131 36.777 42.8697 35.1968C43.6263 33.6166 43.8721 31.8409 43.573 30.1152C43.2739 28.3896 42.4448 26.7993 41.2003 25.5643C39.9558 24.3293 38.3575 23.5107 36.6263 23.2215L36.6296 23.2298Z"
        fill="#80CBC1"
      />
      <path
        d="M8.4656 40C13.141 40 16.9312 36.2186 16.9312 31.554C16.9312 26.8894 13.141 23.108 8.4656 23.108C3.79018 23.108 0 26.8894 0 31.554C0 36.2186 3.79018 40 8.4656 40Z"
        fill="#80CBC1"
      />
    </g>
    <defs>
      <clipPath id="clip0_69_866">
        <rect width="136" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LogoIcon
