import React from 'react'

const MaleGenderIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3774_27968)">
      <circle cx="8" cy="8" r="8" fill="#E4F8FF" />
      <ellipse
        cx="6.66667"
        cy="9.33332"
        rx="2.66667"
        ry="2.66667"
        stroke="#4460AC"
        strokeLinecap="round"
      />
      <path
        d="M8.66675 7.33333L12.0001 4M12.0001 4V6.5M12.0001 4H9.50008"
        stroke="#4460AC"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3774_27968">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default MaleGenderIcon
