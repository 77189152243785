import React from 'react'

const TestPassedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.0625 2.95239H13.4375C13.4375 3.47858 13.018 3.90477 12.5 3.90477C11.982 3.90477 11.5625 3.47858 11.5625 2.95239H5.9375C5.41953 2.95239 5 3.37858 5 3.90477V21.0476C5 21.5738 5.41953 22 5.9375 22H19.0625C19.5805 22 20 21.5738 20 21.0476V3.90477C20 3.37858 19.5805 2.95239 19.0625 2.95239Z"
      fill="url(#paint0_linear_1569_2377)"
    />
    <path
      d="M18.5938 21.0475H6.40625C6.1475 21.0475 5.9375 20.8342 5.9375 20.5714V16.2856H19.0625V20.5714C19.0625 20.8342 18.8525 21.0475 18.5938 21.0475Z"
      fill="url(#paint1_linear_1569_2377)"
    />
    <path
      d="M18.5938 20.0953H6.40625C6.1475 20.0953 5.9375 19.8819 5.9375 19.6191V4.38098C5.9375 4.11812 6.1475 3.90479 6.40625 3.90479H18.5938C18.8525 3.90479 19.0625 4.11812 19.0625 4.38098V19.6191C19.0625 19.8819 18.8525 20.0953 18.5938 20.0953Z"
      fill="#F3F3F3"
    />
    <path
      d="M8.83333 9.21875C8.83333 8.9777 8.90664 8.74207 9.044 8.54165C9.18135 8.34123 9.37657 8.18502 9.60498 8.09277C9.83339 8.00053 10.0847 7.97639 10.3272 8.02342C10.5697 8.07044 10.7924 8.18652 10.9672 8.35696C11.142 8.52741 11.2611 8.74457 11.3093 8.98098C11.3575 9.2174 11.3328 9.46245 11.2382 9.68515C11.1436 9.90784 10.9834 10.0982 10.7778 10.2321C10.5722 10.366 10.3306 10.4375 10.0833 10.4375C9.91918 10.4375 9.75663 10.406 9.60497 10.3447C9.45331 10.2835 9.3155 10.1937 9.19943 10.0806C9.08335 9.96739 8.99128 9.83303 8.92846 9.68516C8.86565 9.53729 8.83332 9.3788 8.83333 9.21875ZM10.0833 11.25C9.53082 11.2501 9.00095 11.4641 8.61026 11.845C8.21957 12.2259 8.00006 12.7425 8 13.2813V14.5C8.00004 14.6077 8.04395 14.711 8.12208 14.7872C8.20021 14.8634 8.30617 14.9062 8.41667 14.9063H11.75C11.8605 14.9062 11.9665 14.8634 12.0446 14.7872C12.1227 14.711 12.1666 14.6077 12.1667 14.5V13.2813C12.1666 12.7425 11.9471 12.2259 11.5564 11.845C11.1657 11.4641 10.6358 11.2501 10.0833 11.25Z"
      fill="#6B909E"
    />
    <path
      d="M15.7812 4.85714V2.95238H13.82C13.6264 2.39857 13.1112 2 12.5 2C11.8888 2 11.3736 2.39857 11.18 2.95238H9.21875V4.85714C9.21875 5.12 9.42875 5.33333 9.6875 5.33333H15.3125C15.5712 5.33333 15.7812 5.12 15.7812 4.85714ZM12.5 2.95238C12.7587 2.95238 12.9688 3.16571 12.9688 3.42857C12.9688 3.69143 12.7587 3.90476 12.5 3.90476C12.2413 3.90476 12.0312 3.69143 12.0312 3.42857C12.0312 3.16571 12.2413 2.95238 12.5 2.95238Z"
      fill="url(#paint2_linear_1569_2377)"
    />
    <path
      d="M15.1467 10.6006L14.2092 9.64824C14.1178 9.55538 14.1178 9.40443 14.2092 9.31157L14.5406 8.9749C14.632 8.88205 14.7806 8.88205 14.872 8.9749L15.3126 9.42205L16.6903 8.02252C16.7817 7.92967 16.9303 7.92967 17.0217 8.02252L17.3531 8.35919C17.4445 8.45205 17.4445 8.603 17.3531 8.69586L15.4781 10.6006C15.3867 10.6935 15.2386 10.6935 15.1467 10.6006Z"
      fill="#42A4EB"
    />
    <path
      d="M15.1467 13.9339L14.2092 12.9815C14.1178 12.8886 14.1178 12.7377 14.2092 12.6448L14.5406 12.3082C14.632 12.2153 14.7806 12.2153 14.872 12.3082L15.3126 12.7553L16.6903 11.3558C16.7817 11.2629 16.9303 11.2629 17.0217 11.3558L17.3531 11.6924C17.4445 11.7853 17.4445 11.9363 17.3531 12.0291L15.4781 13.9339C15.3867 14.0267 15.2386 14.0267 15.1467 13.9339Z"
      fill="#42A4EB"
    />
    <path
      d="M12.2656 16.762H8.04688C7.9175 16.762 7.8125 16.6553 7.8125 16.5239V16.0477C7.8125 15.9162 7.9175 15.8096 8.04688 15.8096H12.2656C12.395 15.8096 12.5 15.9162 12.5 16.0477V16.5239C12.5 16.6553 12.395 16.762 12.2656 16.762Z"
      fill="#195BBC"
    />
    <path
      d="M15.1467 17.2674L14.2092 16.315C14.1178 16.2221 14.1178 16.0712 14.2092 15.9783L14.5406 15.6417C14.632 15.5488 14.7806 15.5488 14.872 15.6417L15.3126 16.0888L16.6903 14.6893C16.7817 14.5964 16.9303 14.5964 17.0217 14.6893L17.3531 15.0259C17.4445 15.1188 17.4445 15.2697 17.3531 15.3626L15.4781 17.2674C15.3867 17.3602 15.2386 17.3602 15.1467 17.2674Z"
      fill="#42A4EB"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1569_2377"
        x1="9.15312"
        y1="1.77763"
        x2="15.2865"
        y2="20.7757"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C77740" />
        <stop offset="1" stopColor="#B0622B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1569_2377"
        x1="12.5"
        y1="18.6504"
        x2="12.5"
        y2="20.5204"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.442" stopColor="#878786" />
        <stop offset="0.594" stopColor="#9F9F9E" />
        <stop offset="0.859" stopColor="#C3C3C3" />
        <stop offset="1" stopColor="#D1D1D1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1569_2377"
        x1="12.9359"
        y1="5.77524"
        x2="12.0261"
        y2="2.04985"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C3CDD9" />
        <stop offset="1" stopColor="#9FA7B0" />
      </linearGradient>
    </defs>
  </svg>
)

export default TestPassedIcon
