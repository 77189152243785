import React from 'react'

interface ArrowRightIconProps extends React.SVGProps<SVGSVGElement> {
  arrowFill?: string
}

const ArrowRightIcon = ({
  arrowFill = '#717171',
  ...restProps
}: ArrowRightIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M8.75628 17.0368C8.41457 17.3715 8.41457 17.9142 8.75628 18.2489C9.09799 18.5837 9.65201 18.5837 9.99372 18.2489L15.2437 13.1061C15.5854 12.7714 15.5854 12.2286 15.2437 11.8939L9.99372 6.75105C9.65201 6.41632 9.09799 6.41632 8.75628 6.75105C8.41457 7.08579 8.41457 7.6285 8.75628 7.96323L13.3876 12.5L8.75628 17.0368Z"
      fill={arrowFill}
    />
  </svg>
)

export default ArrowRightIcon
