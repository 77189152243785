import React from 'react'

const FilterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.75 7.2655V1.25549"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.75 14.7555V11.7655"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 9.5105C14 10.7531 12.9926 11.7605 11.75 11.7605C10.5074 11.7605 9.5 10.7531 9.5 9.5105C9.5 8.26786 10.5074 7.2605 11.75 7.2605V7.2605C12.9926 7.2605 14 8.26786 14 9.5105V9.5105Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.25 5.7655V14.7555"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 3.5105V3.5105C6.5 2.26786 5.49264 1.2605 4.25 1.2605C3.00736 1.2605 2 2.26786 2 3.5105C2 4.75314 3.00736 5.7605 4.25 5.7605V5.7605C5.49264 5.7605 6.5 4.75314 6.5 3.5105V3.5105V3.5105Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FilterIcon
