import React from 'react'

const PhoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.47003 4.05002L5.00003 5.54002C4.60335 5.93246 4.28843 6.39967 4.07351 6.91461C3.85859 7.42956 3.74792 7.98202 3.74792 8.54002C3.74792 9.09802 3.85859 9.65048 4.07351 10.1654C4.28843 10.6804 4.60335 11.1476 5.00003 11.54L12.47 19C12.8625 19.3967 13.3297 19.7116 13.8446 19.9265C14.3596 20.1415 14.912 20.2521 15.47 20.2521C16.028 20.2521 16.5805 20.1415 17.0954 19.9265C17.6104 19.7116 18.0776 19.3967 18.47 19L20 17.53C20.1045 17.4318 20.1877 17.3133 20.2446 17.1817C20.3015 17.0502 20.3308 16.9084 20.3308 16.765C20.3308 16.6217 20.3015 16.4799 20.2446 16.3483C20.1877 16.2167 20.1045 16.0982 20 16L17.76 13.75C17.5599 13.5534 17.2906 13.4432 17.01 13.4432C16.7295 13.4432 16.4601 13.5534 16.26 13.75L15.51 14.5C15.3099 14.6967 15.0406 14.8069 14.76 14.8069C14.4795 14.8069 14.2101 14.6967 14.01 14.5L9.47003 10C9.27338 9.79992 9.16318 9.53058 9.16318 9.25002C9.16318 8.96946 9.27338 8.70012 9.47003 8.50002L10.22 7.75002C10.4167 7.54992 10.5269 7.28058 10.5269 7.00002C10.5269 6.71946 10.4167 6.45012 10.22 6.25002L8.00003 4.05002C7.90185 3.94559 7.78332 3.86236 7.65176 3.80547C7.52019 3.74859 7.37837 3.71924 7.23503 3.71924C7.09169 3.71924 6.94987 3.74859 6.8183 3.80547C6.68674 3.86236 6.56821 3.94559 6.47003 4.05002Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PhoneIcon
