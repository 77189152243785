import React from 'react'

const MapMarkerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 7.00001C2.5 3.98001 4.90467 1.83334 8 1.83334C11.0953 1.83334 13.5 3.98001 13.5 7.00001C13.5 9.45334 12.4107 11.246 11.156 12.4333C10.5982 12.964 9.96541 13.4099 9.278 13.7567C8.67867 14.0533 8.21933 14.1667 8 14.1667C7.78 14.1667 7.32133 14.0533 6.722 13.7567C6.03459 13.4099 5.40184 12.964 4.844 12.4333C3.58933 11.246 2.5 9.45334 2.5 7.00001ZM8 0.833344C4.42867 0.833344 1.5 3.35334 1.5 7.00001C1.5 9.78001 2.744 11.822 4.156 13.1593C4.78621 13.7586 5.50121 14.2617 6.278 14.6527C6.92867 14.9753 7.55267 15.166 8 15.166C8.44667 15.166 9.07133 14.9753 9.722 14.6527C10.4988 14.2617 11.2138 13.7586 11.844 13.1593C13.256 11.822 14.5 9.78068 14.5 7.00001C14.5 3.35334 11.5713 0.833344 8 0.833344ZM6.5 7.33334C6.5 6.93552 6.65804 6.55399 6.93934 6.27268C7.22064 5.99138 7.60218 5.83334 8 5.83334C8.39782 5.83334 8.77936 5.99138 9.06066 6.27268C9.34196 6.55399 9.5 6.93552 9.5 7.33334C9.5 7.73117 9.34196 8.1127 9.06066 8.394C8.77936 8.67531 8.39782 8.83334 8 8.83334C7.60218 8.83334 7.22064 8.67531 6.93934 8.394C6.65804 8.1127 6.5 7.73117 6.5 7.33334ZM8 4.83334C7.6717 4.83334 7.34661 4.89801 7.04329 5.02364C6.73998 5.14928 6.46438 5.33343 6.23223 5.56558C6.00009 5.79772 5.81594 6.07332 5.6903 6.37664C5.56466 6.67995 5.5 7.00504 5.5 7.33334C5.5 7.66165 5.56466 7.98674 5.6903 8.29005C5.81594 8.59337 6.00009 8.86896 6.23223 9.10111C6.46438 9.33326 6.73998 9.51741 7.04329 9.64304C7.34661 9.76868 7.6717 9.83334 8 9.83334C8.66304 9.83334 9.29893 9.56995 9.76777 9.10111C10.2366 8.63227 10.5 7.99639 10.5 7.33334C10.5 6.6703 10.2366 6.03442 9.76777 5.56558C9.29893 5.09674 8.66304 4.83334 8 4.83334Z"
      fill="currentColor"
    />
  </svg>
)

export default MapMarkerIcon
