import React from 'react'

const MessageResponseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.61157 3.78874C6.02784 3.36955 6.55806 3.17985 7.23014 3.08899C7.8864 3 8.72778 3 9.80172 3H10.3143C11.1687 3 11.838 3 12.3696 3.05808C12.9114 3.11709 13.3509 3.23981 13.7258 3.51427C13.9528 3.68038 14.1525 3.88142 14.3174 4.11004C14.59 4.48802 14.7118 4.92969 14.7705 5.47581C14.8281 6.01116 14.8281 6.68515 14.8281 7.54555V7.5877C14.8281 8.4481 14.8281 9.12209 14.7705 9.65697C14.7118 10.2031 14.59 10.6452 14.3174 11.0232C14.1525 11.2518 13.9528 11.4529 13.7258 11.619C13.3505 11.8934 12.9119 12.0162 12.3696 12.0752C11.838 12.1332 11.1687 12.1332 10.3143 12.1332H8.43384C8.14082 12.1332 8.02315 12.1337 7.90966 12.1473C7.71292 12.1717 7.5213 12.225 7.34084 12.3065C7.23619 12.3534 7.13479 12.4143 6.88364 12.5656L6.87294 12.5721C6.67759 12.6901 6.51574 12.788 6.38178 12.8569C6.24737 12.9257 6.10365 12.9866 5.94831 12.9974C5.74311 13.012 5.53821 12.9653 5.3592 12.8633C5.18019 12.7612 5.03501 12.6083 4.94181 12.4236C4.87158 12.2841 4.84879 12.1286 4.83856 11.9768C4.82833 11.826 4.82833 11.6358 4.82833 11.4063V8.0083C4.82833 6.92683 4.82833 6.07954 4.9167 5.4182C5.00693 4.74187 5.19576 4.20793 5.61157 3.78874ZM12.1538 5.81023C12.2463 5.81023 12.335 5.84724 12.4004 5.91312C12.4658 5.97899 12.5026 6.06834 12.5026 6.16151C12.5026 6.25467 12.4658 6.34402 12.4004 6.4099C12.335 6.47578 12.2463 6.51279 12.1538 6.51279H8.43291C8.34039 6.51279 8.25166 6.47578 8.18625 6.4099C8.12083 6.34402 8.08408 6.25467 8.08408 6.16151C8.08408 6.06834 8.12083 5.97899 8.18625 5.91312C8.25166 5.84724 8.34039 5.81023 8.43291 5.81023H12.1538ZM12.5026 8.03499C12.5026 7.94183 12.4658 7.85248 12.4004 7.7866C12.335 7.72073 12.2463 7.68372 12.1538 7.68372H10.2933C10.2008 7.68372 10.1121 7.72073 10.0467 7.7866C9.98126 7.85248 9.9445 7.94183 9.9445 8.03499C9.9445 8.12816 9.98126 8.21751 10.0467 8.28339C10.1121 8.34926 10.2008 8.38627 10.2933 8.38627H12.1538C12.2463 8.38627 12.335 8.34926 12.4004 8.28339C12.4658 8.21751 12.5026 8.12816 12.5026 8.03499Z"
      fill="#4460AC"
    />
    <path
      d="M2.0284 5.59995C2.0284 5.23994 2.2684 4.99994 2.62841 4.99994H3.82844V6.19997H2.62841C2.2684 6.19997 2.0284 5.95996 2.0284 5.59995Z"
      fill="#4460AC"
    />
    <path
      d="M1.42838 8.00001C1.42838 7.64 1.66839 7.4 2.0284 7.4H3.82844V8.60003H2.0284C1.66839 8.60003 1.42838 8.36002 1.42838 8.00001Z"
      fill="#4460AC"
    />
    <path
      d="M0.828369 10.4001C0.828369 10.0401 1.06838 9.80006 1.42838 9.80006H3.82844V11.0001H1.42838C1.06838 11.0001 0.828369 10.7601 0.828369 10.4001Z"
      fill="#4460AC"
    />
  </svg>
)

export default MessageResponseIcon
