import React from 'react'

interface ClockIconProps extends React.SVGProps<SVGSVGElement> {
  useCurrentColor?: boolean
}

const ClockIcon = ({
  useCurrentColor = false,
  ...restProps
}: ClockIconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12H12V6"
      stroke={useCurrentColor ? 'currentColor' : '#717171'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ClockIcon
