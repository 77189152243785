import React from 'react'

const EducationIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6L2 10.0384L12 13.6291L22 10.0384L12 6Z"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 10.0076V15.4875"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.44435 11.6339V16.1605C6.44313 16.3793 6.5092 16.5935 6.63395 16.7749C6.75869 16.9564 6.9363 17.0968 7.14361 17.1777L11.5881 18.9215C11.8528 19.0261 12.1485 19.0261 12.4132 18.9215L16.8577 17.1777C17.0648 17.0967 17.2422 16.9563 17.3667 16.7748C17.4912 16.5933 17.557 16.3792 17.5555 16.1605V11.6339"
      stroke="#717171"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EducationIcon
