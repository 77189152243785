import React from 'react'

const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3132 11.3132C11.407 11.2196 11.5341 11.167 11.6666 11.167C11.7991 11.167 11.9261 11.2196 12.0199 11.3132L14.3532 13.6466C14.4023 13.6923 14.4417 13.7475 14.4691 13.8089C14.4964 13.8702 14.5111 13.9364 14.5123 14.0035C14.5135 14.0707 14.5011 14.1374 14.476 14.1996C14.4508 14.2619 14.4134 14.3184 14.3659 14.3659C14.3184 14.4134 14.2619 14.4508 14.1996 14.476C14.1374 14.5011 14.0707 14.5135 14.0035 14.5123C13.9364 14.5111 13.8702 14.4964 13.8089 14.4691C13.7475 14.4417 13.6923 14.4023 13.6466 14.3532L11.3132 12.0199C11.2196 11.9261 11.167 11.7991 11.167 11.6666C11.167 11.5341 11.2196 11.407 11.3132 11.3132Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.33313 1.83325C5.87444 1.83325 4.47549 2.41271 3.44404 3.44416C2.41259 4.47561 1.83313 5.87456 1.83313 7.33325C1.83313 8.79194 2.41259 10.1909 3.44404 11.2223C4.47549 12.2538 5.87444 12.8333 7.33313 12.8333C8.79182 12.8333 10.1908 12.2538 11.2222 11.2223C12.2537 10.1909 12.8331 8.79194 12.8331 7.33325C12.8331 5.87456 12.2537 4.47561 11.2222 3.44416C10.1908 2.41271 8.79182 1.83325 7.33313 1.83325ZM0.83313 7.33325C0.83313 3.74325 3.74313 0.833252 7.33313 0.833252C10.9231 0.833252 13.8331 3.74325 13.8331 7.33325C13.8331 10.9233 10.9231 13.8333 7.33313 13.8333C3.74313 13.8333 0.83313 10.9233 0.83313 7.33325Z"
      fill="currentColor"
    />
  </svg>
)

export default SearchIcon
